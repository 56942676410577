<!-- =========================================================================================
  File Name: DataListListView.vue
  Description: Data List - List View
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="data-list-list-view" class="data-list-container">
    <feather-icon
      icon="ArrowLeftIcon"
      svgClasses="h-8"
      style="cursor: pointer"
      @click="$route.params.ID > 0 ? $router.push('/Doctor') : $router.push('/Patient')"
    />

    <div v-if="$route.params.ID > 0">
      <doctor-Tab class="mt-4" :mainTabName="'ReservationSession'" />
    </div>
    <div v-if="this.patientObj.Name != null && this.patientObj.Name != ''">
      <patient-Tab class="mt-4" :mainTabName="'Appointments'" />
    </div>

    <vx-card
      ref="filterCard"
      v-bind:title="$t('Filters')"
      class="user-list-filters mb-1"
      collapse-action
    >
      <div class="vx-row">
        <div class="vx-col md:w-1/5 sm:w-1/2 w-full mb-2">
          <label class="text-sm opacity-75">{{ $t("Date From") }}</label>
          <datepicker
            placeholder="from"
            v-model="search.DateFrom"
            class="vx-col w-full"
            name="from"
          ></datepicker>
        </div>

        <div class="vx-col md:w-1/5 sm:w-1/2 w-full mb-2">
          <label class="text-sm opacity-75">{{ $t("Date To") }}</label>
          <datepicker
            placeholder="from"
            v-model="search.DateTo"
            class="w-full"
            name="to"
          ></datepicker>
        </div>

        <div
          v-show="search.IsPrivate != true && !$acl.check('adminPatientAgent')"
          class="vx-col md:w-1/5 sm:w-1/2 w-full mb-2"
        >
          <label class="text-sm opacity-75">{{ $t("doctors") }}</label>
          <v-select
            label="Name"
            class="w-full"
            v-model="search.DoctorID"
            :options="doctors"
            :reduce="(ID) => ID.ID"
          />
        </div>
        <div
          v-show="search.IsPrivate != true"
          class="vx-col md:w-1/5 sm:w-1/2 w-full mb-2"
        >
          <label class="text-sm opacity-75">{{ $t("Patients") }}</label>
          <v-select
            label="Name"
            class="w-full"
            v-model="search.PatientID"
            :options="Patients"
            :reduce="(ID) => ID.ID"
          />
        </div>
        <div class="vx-col md:w-1/5 sm:w-1/2 w-full mb-2">
          <label class="text-sm opacity-75">{{ $t("Status") }}</label>
          <v-select
            label="Name"
            class="w-full"
            v-model="search.StatusID"
            :options="Status"
            :reduce="(ID) => ID.ID"
          />
        </div>
        <div class="vx-col md:w-1/5 sm:w-1/2 w-full mb-2">
          <label class="text-sm opacity-75">{{ $t("Payment Status") }}</label>
          <v-select
            label="Name"
            class="w-full"
            v-model="search.PaymentStatusID"
            :options="PaymentStatuses"
            :reduce="(ID) => ID.ID"
          />
        </div>

        <div class="vx-col w-full">
          <div class="vx-row">
            <vs-button
              style="margin: 10px"
              class="my-3"
              @click="doctorSessionSearch"
              v-scroll-to="'#Scroll'"
              >{{ $t("Search") }}</vs-button
            >
            <vs-button style="margin-left: 10px" class="my-3" @click="resetData">{{
              $t("Reset")
            }}</vs-button>
          </div>
        </div>
      </div>
    </vx-card>

    <vs-table
      id="Scroll"
      ref="table"
      multiple
      v-model="selected"
      pagination
      :max-items="itemsPerPage"
      search
      :data="Reservations"
    >
      <div
        slot="header"
        class="flex flex-wrap-reverse items-center flex-grow justify-between"
      >
        <div
          class="vx-row mb-3"
          v-if="$acl.check('adminPatientAgent') && $store.state.AppActiveUser.IsWrite"
        >
          <div class="vx-col w-full" v-if="this.$route.params.PatientID">
            <div class="mt-8 flex flex-wrap items-center justify-left">
              <vs-button class="mr-6" @click="ReserveAppointment">{{
                $t("Reserve Appointment ")
              }}</vs-button>
                       <vs-button class="mr-6" @click="AppointmentCreation">{{
                $t("Appointment Creation")
              }}</vs-button>
            </div>
          </div>
        
        </div>

        <!-- ITEMS PER PAGE -->
        <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4">
          <div
            class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
          >
            <span class="mr-2"
              >{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} -
              {{
                Reservations.length - currentPage * itemsPerPage > 0
                  ? currentPage * itemsPerPage
                  : Reservations.length
              }}
              of {{ Reservations.length }}</span
            >
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <vs-dropdown-menu>
            <vs-dropdown-item @click="itemsPerPage = 4">
              <span>4</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage = 10">
              <span>10</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage = 15">
              <span>15</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage = 20">
              <span>20</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>

      <template slot="thead">
        <vs-th sort-key="Doctor">{{ $t("Reference Number") }}</vs-th>
        <vs-th sort-key="Doctor" v-if="$route.params.PatientID > 0">{{
          $t("doctor")
        }}</vs-th>
        <vs-th sort-key="Doctor" v-else>{{ $t("patient") }}</vs-th>
        <vs-th sort-key="Day">{{ $t("Date") }}</vs-th>
        <vs-th sort-key="TimeFrom">{{ $t("Time From") }}</vs-th>
        <vs-th sort-key="TimeTo">{{ $t("Time To") }}</vs-th>
        <vs-th sort-key="Status">{{ $t("Status") }}</vs-th>

        <vs-th>{{ $t("Action") }}</vs-th>
      </template>

      <template slot-scope="{ data }">
        <tbody>
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td>
              <p class="product-name font-medium truncate">
                {{ tr.ReferenceNumber }}
              </p>
            </vs-td>
            <vs-td>
              <p
                v-if="$route.params.PatientID && tr.Patient"
                class="product-name font-medium truncate"
              >
                {{ tr.Doctor.Name }}
              </p>
              <p v-else-if="tr.Doctor.Name" class="product-name font-medium truncate">
                {{ tr.Patient.Name }}
              </p>
            </vs-td>

            <vs-td>
              <p v-if="tr.DoctorSession.DayUTC" class="product-name font-medium truncate">
                <!-- {{ tr.DoctorSession.DayUTC.split("T")[0] }} -->
                {{ tr.DoctorSession.DateFrom | formatDate() }}
              </p>
            </vs-td>
            <vs-td>
              <p class="product-name font-medium truncate">
                {{ tr.DoctorSession.DateFrom.slice(11, 16) }}
              </p>
            </vs-td>
            <vs-td>
              <p class="product-name font-medium truncate">
                {{ tr.DoctorSession.DateTo.slice(11, 16) }}
              </p>
            </vs-td>
            <vs-td>
              <p class="product-name font-medium truncate" v-if="tr.Status">
                {{ tr.Status.Name }}
              </p>
            </vs-td>
            <vs-td class="whitespace-no-wrap">
              <!-- edit -->
              <router-link
                :to="{
                  name: 'ReservationSession-Add-Edit',
                  params: { ID: tr.ID },
                }"
                svgClasses="w-5 h-5 hover:text-primary stroke-current"
              >
                <vx-tooltip :text="$t('Edit')" position="top" style="display: inline">
                  <feather-icon
                    icon="EditIcon"
                    svgClasses="w-6 h-6 hover:text-danger stroke-current"
                    class="ml-2"
                  />
                </vx-tooltip>
              </router-link>
              <!-- re appointment -->
              <vx-tooltip
                :text="$t('Edit Session Date')"
                position="top"
                style="display: inline"
              >
                <feather-icon
                  v-if="tr.StatusID != 3 && tr.StatusID != 5"
                  icon="RefreshCcwIcon"
                  svgClasses="w-6 h-6 hover:text-danger stroke-current"
                  class="ml-2"
                  @click="OpentReAppointmentPopup(tr)"
                />
              </vx-tooltip>

              <!-- cancel -->
              <vx-tooltip
                :text="$t('CancleSession')"
                position="top"
                style="display: inline"
              >
                <feather-icon
                  icon="XOctagonIcon"
                  svgClasses="w-6 h-6 hover:text-danger stroke-current"
                  class="ml-2"
                  v-if="
                    new Date(tr.DoctorSession.DateFrom) > new Date() && tr.StatusID < 3
                  "
                  @click="CancelSession(tr)"
                />
              </vx-tooltip>
            </vs-td>
          </vs-tr>
        </tbody>
      </template>
    </vs-table>
    <vs-popup title="" :active.sync="showEditSessionDialog">
      <EditSessionAppointmet
        v-if="showEditSessionDialog"
        @closePop="
          showEditSessionDialog = false;
          doctorSessionSearch();
        "
        :editSessionModel="reAppModel"
      ></EditSessionAppointmet>
    </vs-popup>

    <vs-popup title="Cancel Appointment" :active.sync="showCancelDialog">
      <div>
        <div class="text-center">
          <h2 class="P-Text" style="color: black">
            {{ $t("Cancellation Conditions") }}
          </h2>
          <ul class="m-5 P-Text ulText">
            <li style="color: black">
              {{
                $t(
                  "from the booking date to one week before your online consultation: the cancellation is free of charge"
                )
              }}
            </li>
            <li style="color: black" class="mt-2">
              {{ $t("From 6 days to 72 hours: 50% of the paid amount is refunded") }}
            </li>
            <li style="color: black" class="mt-2">
              {{ $t("From 72 to consultation date: Non-refundable") }}
            </li>
          </ul>
          <!--
        <p class="m-5 P-Text" style="color: white">
          {{ $t("AppointmentPatientCondition") }}
        </p> -->
          <p class="P-Text" style="color: orange">
            {{ $t("You are going to cancel your Online Appointment") }}
          </p>
          <p class="P-Text" style="color: orange">
            {{ $t("Are you sure?") }}
          </p>

          <div class="P-Text">
            <u style="color: black" class="m-1" @click="showCancelDialog = false"
              >{{ $t("Back") }}
            </u>
            <vs-button
              class="m-1"
              color="white"
              style="width: 30%"
              @click="cancelSessionConfirm"
            >
              <p style="color: #004477; font-weight: bold">{{ $t("Confirm") }}</p>
            </vs-button>
          </div>
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import moduleReservation from "@/store/reservation/ReservationSession/moduleReservation.js";
import moduleDoctor from "@/store/doctor/moduleDoctor.js";
import Datepicker from "vuejs-datepicker";
import modulePatient from "@/store/Patient/modulePatient.js";
import EditSessionAppointmet from "./EditSessionAppointmet";
import doctorTab from "@/views/tabs/doctorTab.vue";
import patientTab from "@/views/tabs/patientTab.vue";

export default {
  components: {
    Datepicker,
    EditSessionAppointmet,
    doctorTab,
    patientTab,
  },
  data() {
    return {
      selected: [],
      search: {},
      showEditSessionDialog: false,
      PatientID: "",
      patientObj: "",
      showCancelDialog: false,
      //Patients: [],
      Status: [],
      doctorModel: {},
      reAppModel: {},
      PaymentStatuses: [],
      itemsPerPage: 10,
      isMounted: false,
      rowDataForDelete: {},
      activeConfirm: false,
      CancelSessionModel: {},
    };
  },
  computed: {
    currentPage() {
      if (this.isMounted) {
        return this.$refs.table.currentx;
      }
      return 0;
    },
    Reservations() {
      return this.$store.state.ReservationList.Reservations;
    },
    doctors() {
      return this.$store.state.DoctorList.doctors;
    },
    Patients() {
      debugger;
      return this.$store.state.patientList.patients;
    },
  },

  methods: {
    AppointmentCreation(){
      this.$router.push({
        name: "ReservationSession-Add-Edit",
        params: {ID:0, PatientID: this.$route.params.PatientID },
      });
    },
    OpentReAppointmentPopup(session) {
      debugger;
      // var dayNow = new Date();
      // var myDate = new Date(session.DoctorSession.DayUTC);
      // dayNow = new Date(dayNow.getTime() + 3 * 24 * 60 * 60 * 1000);
      // var date1 = dayNow.getTime();
      // var date2 = myDate.getTime();
      // if (date1 > date2 ) {
      //   this.$vs.notify({
      //     color: "warning",
      //     title: "Can't reappointment",
      //     text: this.$t(
      //       "can't edit reservation before 72h , please cancel it and rebooking"
      //     ),
      //   });
      // }
    //  else
    //  {
        debugger;
        this.reAppModel = session;
        this.showEditSessionDialog = true;
  //    }
    },
    cancelSessionConfirm() {
      debugger;
      var obj = {};
      obj.PatientID = this.CancelSessionModel.PatientID;
      obj.PatientReservationSessionID = this.CancelSessionModel.ID;
      obj.DoctorSessionID = this.CancelSessionModel.DoctorSessionID;
      obj.DoctorID = this.CancelSessionModel.DoctorID;
      obj.StatusID = 3;

      this.$store
        .dispatch("patientList/CancelSession", obj)
        .then((res) => {
          debugger;

          this.$vs.notify({
            color: "success",
            title: "Cancle appointment",
            text: this.$t("Cancle appointment Done"),
          });
          this.showCancelDialog = false;
          console.log(res);
          this.$store.dispatch(
            "ReservationList/SearchPatientReservationSessions",
            this.search
          );
        })
        .catch((err) => {
          debugger;
          window.showError();
        });
    },

    ReserveAppointment() {
      this.$router.push({
        name: "AppointmentSearch",
        params: { ID: this.$route.params.PatientID },
      });
    },

    CancelSession(CancelSessionModel) {
      debugger;

      this.showCancelDialog = true;
      this.CancelSessionModel = CancelSessionModel;

      debugger;
    },
    inialValues() {
      this.search.DateFrom =
        new Date().getFullYear() + "-" + (new Date().getMonth() + 1) + "-1";

      this.search.DateTo =
        new Date().getFullYear() +
        "-" +
        (new Date().getMonth() + 1) +
        "-" +
        new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getDate();
      const ID = this.$route.params.ID;
      debugger;
      const PatientID = parseInt(this.$route.params.PatientID);

      if (this.$route.params.PatientID != null) {
        debugger;
        this.$set(this.search, "PatientID", PatientID);
        //this.search.PatientID = PatientID;
      }
      debugger;
      //  this.search.IsNext = true;
      if (ID > 0) {
        debugger;
        this.search.IsPrivate = true; // de lw hwa gai mn doctor or patient data  aw 7aga
      } else if (PatientID > 0) {
        debugger;
        this.search.IsPrivate = false;
      }
    },
    resetData() {
      debugger;
      this.inialValues();

      debugger;
      this.search.StatusID = null;
      this.search.PaymentStatusID = null;
      this.doctorSessionSearch();
    },
    doctorSessionSearch() {
      debugger;
      const ID = this.$route.params.ID;
      if (ID > 0) {
        this.search.DoctorID = ID;
      } else this.search.DoctorID = null;

      debugger;
      this.$store.dispatch(
        "ReservationList/SearchPatientReservationSessions",
        this.search
      );
    },

    deleteData(data) {
      this.$store
        .dispatch("ReservationList/DeleteDoctorSession", data)
        .then(() => {
          window.showDeleteSuccess();
        })
        .catch((err) => {
          console.error(err);
        });
    },
    GetPatient() {
      debugger;
      this.$store
        .dispatch("patientList/SearchPatients", {})
        .then((res) => {
          debugger;
          //this.Patients = res.data.Data;
          this.$vs.loading.close();
        })
        .catch(() => {
          this.$vs.loading.close();
        });
    },
    GetAllStatuses() {
      this.$store
        .dispatch("ReservationList/GetAllStatuses")
        .then((res) => {
          this.Status = res.data.Data;
        })
        .catch((err) => {
          console.error(err);
        });
    },

    allowEdit(date) {
      var dayNow = new Date();
      var myDate = new Date(date);
      dayNow = new Date(dayNow.getTime() + 3 * 24 * 60 * 60 * 1000);
      var date1 = dayNow.getTime();
      var date2 = myDate.getTime();
      if (date1 > date2) {
        return false;
      } else return true;
    },
    GetAllPaymentStatuses() {
      this.$store
        .dispatch("ReservationList/GetAllPaymentStatuses")
        .then((res) => {
          debugger;
          this.PaymentStatuses = res.data.Data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },

  created() {
    debugger;
    if (!moduleDoctor.isRegistered) {
      this.$store.registerModule("DoctorList", moduleDoctor);
      moduleDoctor.isRegistered = true;
    }

    if (!moduleReservation.isRegistered) {
      this.$store.registerModule("ReservationList", moduleReservation);
      moduleReservation.isRegistered = true;
    }
    this.$store.dispatch("DoctorList/GetAllDoctors", {});

    if (!modulePatient.isRegistered) {
      this.$store.registerModule("patientList", modulePatient);
      modulePatient.isRegistered = true;
    }
    this.GetPatient();
    debugger;

    this.GetAllStatuses();
    this.GetAllPaymentStatuses();
    this.inialValues();
    debugger;

    if (this.$route.params.ID > 0) {
      this.search.DoctorID = this.$route.params.ID;
      this.$store.dispatch("DoctorList/GetDoctor", this.search.DoctorID).then((res) => {
        this.doctorModel = res.data.Data;
        this.$store.commit("SET_SetTitle", "Dr. " + this.doctorModel.Name);
      });
    }
    debugger;

    this.$store.dispatch("ReservationList/SearchPatientReservationSessions", this.search);
    debugger;
    if (this.$route.params.PatientID != null) {
      debugger;
      this.$store
        .dispatch("patientList/GetPatient", this.$route.params.PatientID)
        .then((res) => {
          debugger;
          this.patientObj = res.data.Data;
          debugger;

          this.$store.commit("SET_SetTitle", "" + this.patientObj.NameEN);
          debugger;
        })
        .catch((err) => {
          debugger;
          this.$vs.loading.close();
        });
    }

    debugger;
  },
  mounted() {
    this.isMounted = true;
  },
};
</script>

<style lang="scss">
#data-list-list-view {
  .vs-con-table {
    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 20px;
          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }
          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }
        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check {
        padding: 0 15px !important;
      }
      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
<style>
.ulText {
  list-style-type: square;
  margin: 0 !important;
  padding: 2rem;
  text-align: left !important;
}
</style>
